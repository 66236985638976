<template>
  <div class="card" style="margin: auto;" v-if="!isLoading">
    <h1 style="margin-top: 50px">Add User</h1>
      <RegistrationFormComposition
          :superUserRegistering="superUserRegistering"
          :submitText="'Enroll New User'"
          :authToken="authToken"
          :idEsu="user.roleHierarchyId >= 2 ? user.idEsu : -1"
          :idDistrict="user.roleHierarchyId >= 3 ? user.idDistrict : -1"
          :idSchool="user.roleHierarchyId === 4 ? user.idSchool : -1"
          :registeringUserPositionId="user.roleHierarchyId"
          :registering-user-id="user.idUser"
          :selfRegistering="false"
          :default-country="defaultCountry"
          :default-state="defaultState"
          :editing-existing="false"
          :default-outstate-org-name="organizationName"
      />
    </div>
</template>

<script>
import RegistrationFormComposition from "@/views/Registration/RegistrationFormComposition";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {API_URL} from "../../../Constants";
import axios from "axios";


export default {
  name: "SuperUserRegistering",
  components: {RegistrationFormComposition},
  setup() {
    const store = useStore();

    const authUser = computed(() => store.getters['auth/user']);
    const authToken = computed(() => store.getters['auth/authenticated']);

    const user = ref({});
    const isLoading = ref(true);

    const defaultCountry = ref("US");
    const defaultState = ref("NE");

    onBeforeMount(() => {
      if (authUser.value === null) location.replace("/")
      getUser();
    })


    async function getUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + authUser.value.idUser;
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        user.value = result.data;
        // console.log(result.data);
        defaultCountry.value = user.value.countryCode;
        defaultState.value = user.value.stateCode;
      }).finally(() => {
        isLoading.value = false;
      })
    }

    const superUserRegistering = computed(() => {
      return user.value.roleHierarchyId === 1;
    });

    const organizationName = computed(() => {
      return (user.value.outStateOrgName !== null && user.value.outStateOrgName !== undefined) ? user.value.outStateOrgName : "-1";
    })

    return {
      superUserRegistering,
      user,
      authToken,
      isLoading,
      defaultCountry,
      defaultState,
      organizationName
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0);
  border-radius: 0;
  padding: 1%;
  /*max-width: 420px;*/
}

form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
</style>